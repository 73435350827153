import { SimpleArrow } from '@dbs/react-ui-components/components/atoms/icons/SimpleArrow';
import styled from 'styled-components';

export const Anchor = styled.a`
  padding: 1rem 0.25rem;
  border-bottom: 1px solid rgb(224, 224, 224);
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: inherit;
  &:hover {
    background-color: #f9f9f9;
  }
`;

export const ArrowRight = styled(SimpleArrow)`
  transform: rotate(-90deg);
  height: 13px;
  fill: #0693ca;
`;
