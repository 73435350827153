import styled from 'styled-components';

export const Container = styled.header`
  height: 5rem;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
`;

export const Content = styled.div`
  width: 60rem;
  max-width: 100vw;
  height: 100%;
  padding: 1rem 2rem;
  margin: 0px auto;

  > img {
    height: 100%;
  }
`;
