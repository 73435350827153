import { Status } from '@dbs/portinsider-ui-components/generated/gql';
import { IconButton } from '@dbs/react-ui-components/components/atoms/IconButton';
import { Popover } from '@dbs/react-ui-components/components/molecules/Popover';
import styled from 'styled-components';

export const Row = styled.li`
  padding: 0.25rem 0.25rem;
  border-bottom: 1px solid rgb(224, 224, 224);
  transition: background-color 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
`;

export const Actions = styled.div`
  display: flex;
`;

export const StyledPopover = styled(Popover)`
  & .card {
    width: 20rem;
    right: -12px;
    top: 45px;
    font-size: 14px;
  }
`;

export const StatusButton = styled(IconButton)<{ status: Status }>`
  svg {
    fill: ${({ status, theme }) => (status === Status.Infected ? theme.colors.red : null)};
  }
`;
