export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSEmail: { input: any; output: any; }
};

export type AddDestinationInput = {
  documentType: Scalars['String']['input'];
  fromOrganizationCode: Scalars['String']['input'];
  fromOrganizationType: Scalars['String']['input'];
  toOrganizationCode: Scalars['String']['input'];
  toOrganizationType: Scalars['String']['input'];
};

export type Attachment = {
  __typename?: 'Attachment';
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: Status;
  url: Scalars['String']['output'];
};

export type AttachmentInput = {
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  status: Status;
  url: Scalars['String']['input'];
};

export type Attachments = {
  __typename?: 'Attachments';
  application: Scalars['String']['output'];
  attachments: Array<Attachment>;
  owner: Scalars['String']['output'];
  parentId: Scalars['String']['output'];
  parentType: Scalars['String']['output'];
};

export type AttachmentsInput = {
  application: Scalars['String']['input'];
  attachments: Array<AttachmentInput>;
  owner: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
  parentType: Scalars['String']['input'];
};

export type Configuration = {
  __typename?: 'Configuration';
  community: Scalars['ID']['output'];
  enums: Array<Enumeration>;
};

export type DeleteEnumerationValueInput = {
  code: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type Destination = {
  __typename?: 'Destination';
  organizationCode: Scalars['String']['output'];
  organizationType: Scalars['String']['output'];
};

export type Document = {
  __typename?: 'Document';
  attachments: Array<Attachment>;
  crn: Scalars['String']['output'];
  documentId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rejectionMessages?: Maybe<Array<Scalars['String']['output']>>;
  scope: Scope;
  status: Scalars['String']['output'];
};

export enum DocumentStatus {
  Acknowledged = 'ACKNOWLEDGED',
  Draft = 'DRAFT',
  None = 'NONE',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED'
}

export type DocumentUpdate = {
  __typename?: 'DocumentUpdate';
  crn: Scalars['String']['output'];
  document: Document;
  organization: Scalars['String']['output'];
};

export type Documents = {
  __typename?: 'Documents';
  documents: Array<Document>;
  organization: Scalars['String']['output'];
};

export type EnumValue = {
  __typename?: 'EnumValue';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentValue?: Maybe<Scalars['String']['output']>;
};

export type EnumValueInput = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentValue?: InputMaybe<Scalars['String']['input']>;
};

export type Enumeration = {
  __typename?: 'Enumeration';
  parentType?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  values: Array<Maybe<EnumValue>>;
};

export type EnumerationInput = {
  type: Scalars['String']['input'];
  values: Array<EnumValueInput>;
};

export type EnumerationValueInput = {
  parentType?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  value: EnumValueInput;
};

export type GetAttachmentsInput = {
  application: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
  parentType: Scalars['String']['input'];
};

export type GetDocumentsInput = {
  crn: Scalars['String']['input'];
};

export type IdFilterInput = {
  beginsWith?: InputMaybe<Scalars['ID']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  ge?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  le?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
};

export type ModelOrganizationFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelOrganizationFilterInput>>>;
  code?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ModelOrganizationFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelOrganizationFilterInput>>>;
};

export type ModelUserFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUserFilterInput>>>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ModelUserFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUserFilterInput>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addDestination?: Maybe<Routing>;
  createAdmin?: Maybe<User>;
  createOrganization?: Maybe<Organization>;
  createUser?: Maybe<User>;
  deleteEnumValue?: Maybe<Configuration>;
  deleteUser: User;
  notifyCommunityAttachmentsUpdate?: Maybe<Attachments>;
  notifyDocumentUpdate?: Maybe<DocumentUpdate>;
  notifyOrganizationAttachmentsUpdate?: Maybe<Attachments>;
  publish?: Maybe<DocumentUpdate>;
  putEnum?: Maybe<Configuration>;
  putEnumValue?: Maybe<Configuration>;
  refreshClientSecret: RefreshClientSecretResult;
  setAssignableRoles?: Maybe<Organization>;
  setRoles: User;
};


export type MutationAddDestinationArgs = {
  input: AddDestinationInput;
};


export type MutationCreateAdminArgs = {
  email: Scalars['AWSEmail']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationCode: Scalars['String']['input'];
  organizationType: Scalars['String']['input'];
};


export type MutationCreateOrganizationArgs = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  email: Scalars['AWSEmail']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteEnumValueArgs = {
  input: DeleteEnumerationValueInput;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationNotifyCommunityAttachmentsUpdateArgs = {
  input: GetAttachmentsInput;
  organization: Scalars['String']['input'];
};


export type MutationNotifyDocumentUpdateArgs = {
  organization: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
};


export type MutationNotifyOrganizationAttachmentsUpdateArgs = {
  input: GetAttachmentsInput;
  organization: Scalars['String']['input'];
};


export type MutationPublishArgs = {
  input: PublishInput;
};


export type MutationPutEnumArgs = {
  input: EnumerationInput;
};


export type MutationPutEnumValueArgs = {
  input: EnumerationValueInput;
};


export type MutationSetAssignableRolesArgs = {
  assignableRoles: Array<Scalars['String']['input']>;
  organizationCode: Scalars['String']['input'];
  organizationType: Scalars['String']['input'];
};


export type MutationSetRolesArgs = {
  roles: Array<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  assignableRoles: Array<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  communityCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type OwnOrganization = {
  __typename?: 'OwnOrganization';
  assignableRoles: Array<Scalars['String']['output']>;
  communityName: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type PublishInput = {
  crn: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  getAttachments?: Maybe<Attachments>;
  getCommunityAttachments?: Maybe<Attachments>;
  getCommunityCode: Scalars['String']['output'];
  getConfiguration?: Maybe<Configuration>;
  getDocuments?: Maybe<Documents>;
  getOrganization?: Maybe<OwnOrganization>;
  getOrganizationAttachments?: Maybe<Attachments>;
  getOwner: Scalars['String']['output'];
  listCommunityRoles?: Maybe<Array<Scalars['String']['output']>>;
  listMyRoles: Array<Scalars['String']['output']>;
  listOrganizationTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  listOrganizations?: Maybe<Array<Maybe<Organization>>>;
  listPorts?: Maybe<Array<Maybe<Organization>>>;
  listRoles: Array<Scalars['String']['output']>;
  listUsers?: Maybe<Array<Maybe<User>>>;
};


export type QueryGetAttachmentsArgs = {
  input: GetAttachmentsInput;
};


export type QueryGetCommunityAttachmentsArgs = {
  input: GetAttachmentsInput;
};


export type QueryGetDocumentsArgs = {
  input: GetDocumentsInput;
};


export type QueryGetOrganizationAttachmentsArgs = {
  input: GetAttachmentsInput;
};


export type QueryListOrganizationsArgs = {
  filter?: InputMaybe<ModelOrganizationFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListUsersArgs = {
  filter?: InputMaybe<ModelUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type RefreshClientSecretResult = {
  __typename?: 'RefreshClientSecretResult';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
};

export type Routing = {
  __typename?: 'Routing';
  classification: Scalars['String']['output'];
  community: Scalars['ID']['output'];
  destinations?: Maybe<Array<Destination>>;
};

export type Scope = {
  __typename?: 'Scope';
  application: Scalars['String']['output'];
  parentId: Scalars['String']['output'];
  parentType: Scalars['String']['output'];
};

export type ScopeInput = {
  application: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
  parentType: Scalars['String']['input'];
};

export enum Status {
  Clean = 'CLEAN',
  Deleted = 'DELETED',
  Infected = 'INFECTED',
  PendingVirusCheck = 'PENDING_VIRUS_CHECK'
}

export type StringFilterInput = {
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ge?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  le?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  attachmentsUpdatedSubscription?: Maybe<Attachments>;
  communityAttachmentsUpdatedSubscription?: Maybe<Attachments>;
  createOrganizationSubscription?: Maybe<Organization>;
  createUserSubscription?: Maybe<User>;
  deleteUserSubscription?: Maybe<User>;
  documentUpdatedSubscription?: Maybe<DocumentUpdate>;
  organizationAttachmentsUpdatedSubscription?: Maybe<Attachments>;
  userUpdatedSubscription?: Maybe<User>;
};


export type SubscriptionAttachmentsUpdatedSubscriptionArgs = {
  application: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
  parentType: Scalars['String']['input'];
};


export type SubscriptionCommunityAttachmentsUpdatedSubscriptionArgs = {
  application: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
  parentType: Scalars['String']['input'];
};


export type SubscriptionCreateOrganizationSubscriptionArgs = {
  communityCode: Scalars['String']['input'];
};


export type SubscriptionCreateUserSubscriptionArgs = {
  owner: Scalars['String']['input'];
};


export type SubscriptionDeleteUserSubscriptionArgs = {
  owner: Scalars['String']['input'];
};


export type SubscriptionDocumentUpdatedSubscriptionArgs = {
  crn: Scalars['String']['input'];
  organization: Scalars['String']['input'];
};


export type SubscriptionOrganizationAttachmentsUpdatedSubscriptionArgs = {
  application: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
  parentType: Scalars['String']['input'];
};


export type SubscriptionUserUpdatedSubscriptionArgs = {
  owner: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['AWSEmail']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: Scalars['String']['output'];
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CommunityAttachmentsUpdatedSubscriptionSubscriptionVariables = Exact<{
  owner: Scalars['String']['input'];
  application: Scalars['String']['input'];
  parentType: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
}>;


export type CommunityAttachmentsUpdatedSubscriptionSubscription = { __typename?: 'Subscription', communityAttachmentsUpdatedSubscription?: { __typename?: 'Attachments', owner: string, application: string, parentType: string, parentId: string, attachments: Array<{ __typename?: 'Attachment', id: string, name: string, mimeType: string, url: string, status: Status }> } | null };

export type AttachmentFragment = { __typename?: 'Attachment', id: string, name: string, mimeType: string, url: string, status: Status };

export type AttachmentsFragment = { __typename?: 'Attachments', owner: string, application: string, parentType: string, parentId: string, attachments: Array<{ __typename?: 'Attachment', id: string, name: string, mimeType: string, url: string, status: Status }> };

export type GetCommunityAttachmentsQueryVariables = Exact<{
  input: GetAttachmentsInput;
}>;


export type GetCommunityAttachmentsQuery = { __typename?: 'Query', getCommunityAttachments?: { __typename?: 'Attachments', owner: string, application: string, parentType: string, parentId: string, attachments: Array<{ __typename?: 'Attachment', id: string, name: string, mimeType: string, url: string, status: Status }> } | null };

export type GetCommunityCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCommunityCodeQuery = { __typename?: 'Query', getCommunityCode: string };

export type GetOwnerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOwnerQuery = { __typename?: 'Query', getOwner: string };

export type GetUserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRolesQuery = { __typename?: 'Query', listRoles: Array<string> };
